"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _LoginResult = _interopRequireDefault(require("../model/LoginResult"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Auth service.
* @module api/AuthApi
* @version 2.5.0
*/
class AuthApi {
  /**
  * Constructs a new AuthApi. 
  * @alias module:api/AuthApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the authCsrfToken operation.
   * @callback module:api/AuthApi~authCsrfTokenCallback
   * @param {String} error Error message, if any.
   * @param {String} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * The CSRF token to use in POST, PUT and DELETE requests.
   * Needs role: user
   * @param {module:api/AuthApi~authCsrfTokenCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link String}
   */
  authCsrfToken(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';
    return this.apiClient.callApi('/user/auth/csrf-token', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the logout operation.
   * @callback module:api/AuthApi~logoutCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * User logout.
   * Needs role: user
   * @param {module:api/AuthApi~logoutCallback} callback The callback function, accepting three arguments: error, data, response
   */
  logout(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/auth/logout', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the result operation.
   * @callback module:api/AuthApi~resultCallback
   * @param {String} error Error message, if any.
   * @param {module:model/LoginResult} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Result of last SSO attempt.
   * @param {module:api/AuthApi~resultCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/LoginResult}
   */
  result(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _LoginResult.default;
    return this.apiClient.callApi('/user/auth/result', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AuthApi;