"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CharacterNameChange model module.
 * @module model/CharacterNameChange
 * @version 2.5.0
 */
class CharacterNameChange {
  /**
   * Constructs a new <code>CharacterNameChange</code>.
   * A previous character name.
   * @alias module:model/CharacterNameChange
   * @param oldName {String} 
   * @param changeDate {Date} 
   */
  constructor(oldName, changeDate) {
    CharacterNameChange.initialize(this, oldName, changeDate);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, oldName, changeDate) {
    obj['oldName'] = oldName;
    obj['changeDate'] = changeDate;
  }

  /**
   * Constructs a <code>CharacterNameChange</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CharacterNameChange} obj Optional instance to populate.
   * @return {module:model/CharacterNameChange} The populated <code>CharacterNameChange</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CharacterNameChange();
      if (data.hasOwnProperty('oldName')) {
        obj['oldName'] = _ApiClient.default.convertToType(data['oldName'], 'String');
      }
      if (data.hasOwnProperty('changeDate')) {
        obj['changeDate'] = _ApiClient.default.convertToType(data['changeDate'], 'Date');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CharacterNameChange</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CharacterNameChange</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CharacterNameChange.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['oldName'] && !(typeof data['oldName'] === 'string' || data['oldName'] instanceof String)) {
      throw new Error("Expected the field `oldName` to be a primitive type in the JSON string but got " + data['oldName']);
    }
    return true;
  }
}
CharacterNameChange.RequiredProperties = ["oldName", "changeDate"];

/**
 * @member {String} oldName
 */
CharacterNameChange.prototype['oldName'] = undefined;

/**
 * @member {Date} changeDate
 */
CharacterNameChange.prototype['changeDate'] = undefined;
var _default = exports.default = CharacterNameChange;