"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _PluginConfigurationURL = _interopRequireDefault(require("./PluginConfigurationURL"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PluginConfigurationFile model module.
 * @module model/PluginConfigurationFile
 * @version 2.5.0
 */
class PluginConfigurationFile {
  /**
   * Constructs a new <code>PluginConfigurationFile</code>.
   * Plugin configuration from YAML file.  API: The required properties are necessary for the service page where users register their account. The rest is necessary for the admin page.
   * @alias module:model/PluginConfigurationFile
   * @param properties {Array.<String>} 
   * @param actions {Array.<String>} 
   */
  constructor(properties, actions) {
    PluginConfigurationFile.initialize(this, properties, actions);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, properties, actions) {
    obj['properties'] = properties;
    obj['actions'] = actions;
  }

  /**
   * Constructs a <code>PluginConfigurationFile</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PluginConfigurationFile} obj Optional instance to populate.
   * @return {module:model/PluginConfigurationFile} The populated <code>PluginConfigurationFile</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PluginConfigurationFile();
      if (data.hasOwnProperty('directoryName')) {
        obj['directoryName'] = _ApiClient.default.convertToType(data['directoryName'], 'String');
      }
      if (data.hasOwnProperty('URLs')) {
        obj['URLs'] = _ApiClient.default.convertToType(data['URLs'], [_PluginConfigurationURL.default]);
      }
      if (data.hasOwnProperty('textTop')) {
        obj['textTop'] = _ApiClient.default.convertToType(data['textTop'], 'String');
      }
      if (data.hasOwnProperty('textAccount')) {
        obj['textAccount'] = _ApiClient.default.convertToType(data['textAccount'], 'String');
      }
      if (data.hasOwnProperty('textRegister')) {
        obj['textRegister'] = _ApiClient.default.convertToType(data['textRegister'], 'String');
      }
      if (data.hasOwnProperty('textPending')) {
        obj['textPending'] = _ApiClient.default.convertToType(data['textPending'], 'String');
      }
      if (data.hasOwnProperty('configurationData')) {
        obj['configurationData'] = _ApiClient.default.convertToType(data['configurationData'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('types')) {
        obj['types'] = _ApiClient.default.convertToType(data['types'], ['String']);
      }
      if (data.hasOwnProperty('oneAccount')) {
        obj['oneAccount'] = _ApiClient.default.convertToType(data['oneAccount'], 'Boolean');
      }
      if (data.hasOwnProperty('properties')) {
        obj['properties'] = _ApiClient.default.convertToType(data['properties'], ['String']);
      }
      if (data.hasOwnProperty('showPassword')) {
        obj['showPassword'] = _ApiClient.default.convertToType(data['showPassword'], 'Boolean');
      }
      if (data.hasOwnProperty('actions')) {
        obj['actions'] = _ApiClient.default.convertToType(data['actions'], ['String']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PluginConfigurationFile</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PluginConfigurationFile</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of PluginConfigurationFile.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['directoryName'] && !(typeof data['directoryName'] === 'string' || data['directoryName'] instanceof String)) {
      throw new Error("Expected the field `directoryName` to be a primitive type in the JSON string but got " + data['directoryName']);
    }
    if (data['URLs']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['URLs'])) {
        throw new Error("Expected the field `URLs` to be an array in the JSON data but got " + data['URLs']);
      }
      // validate the optional field `URLs` (array)
      for (const item of data['URLs']) {
        _PluginConfigurationURL.default.validateJSON(item);
      }
      ;
    }
    // ensure the json data is a string
    if (data['textTop'] && !(typeof data['textTop'] === 'string' || data['textTop'] instanceof String)) {
      throw new Error("Expected the field `textTop` to be a primitive type in the JSON string but got " + data['textTop']);
    }
    // ensure the json data is a string
    if (data['textAccount'] && !(typeof data['textAccount'] === 'string' || data['textAccount'] instanceof String)) {
      throw new Error("Expected the field `textAccount` to be a primitive type in the JSON string but got " + data['textAccount']);
    }
    // ensure the json data is a string
    if (data['textRegister'] && !(typeof data['textRegister'] === 'string' || data['textRegister'] instanceof String)) {
      throw new Error("Expected the field `textRegister` to be a primitive type in the JSON string but got " + data['textRegister']);
    }
    // ensure the json data is a string
    if (data['textPending'] && !(typeof data['textPending'] === 'string' || data['textPending'] instanceof String)) {
      throw new Error("Expected the field `textPending` to be a primitive type in the JSON string but got " + data['textPending']);
    }
    // ensure the json data is a string
    if (data['configurationData'] && !(typeof data['configurationData'] === 'string' || data['configurationData'] instanceof String)) {
      throw new Error("Expected the field `configurationData` to be a primitive type in the JSON string but got " + data['configurationData']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['types'])) {
      throw new Error("Expected the field `types` to be an array in the JSON data but got " + data['types']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['properties'])) {
      throw new Error("Expected the field `properties` to be an array in the JSON data but got " + data['properties']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['actions'])) {
      throw new Error("Expected the field `actions` to be an array in the JSON data but got " + data['actions']);
    }
    return true;
  }
}
PluginConfigurationFile.RequiredProperties = ["properties", "actions"];

/**
 * Directory where the plugin.yml file is stored.  Only from database but always set when the data from the file is read.
 * @member {String} directoryName
 */
PluginConfigurationFile.prototype['directoryName'] = undefined;

/**
 * @member {Array.<module:model/PluginConfigurationURL>} URLs
 */
PluginConfigurationFile.prototype['URLs'] = undefined;

/**
 * @member {String} textTop
 */
PluginConfigurationFile.prototype['textTop'] = undefined;

/**
 * @member {String} textAccount
 */
PluginConfigurationFile.prototype['textAccount'] = undefined;

/**
 * @member {String} textRegister
 */
PluginConfigurationFile.prototype['textRegister'] = undefined;

/**
 * @member {String} textPending
 */
PluginConfigurationFile.prototype['textPending'] = undefined;

/**
 * @member {String} configurationData
 */
PluginConfigurationFile.prototype['configurationData'] = undefined;

/**
 * @member {String} name
 */
PluginConfigurationFile.prototype['name'] = undefined;

/**
 * Not part of the file but will be set when the plugin implementation is loaded.
 * @member {Array.<String>} types
 */
PluginConfigurationFile.prototype['types'] = undefined;

/**
 * @member {Boolean} oneAccount
 */
PluginConfigurationFile.prototype['oneAccount'] = undefined;

/**
 * @member {Array.<String>} properties
 */
PluginConfigurationFile.prototype['properties'] = undefined;

/**
 * @member {Boolean} showPassword
 */
PluginConfigurationFile.prototype['showPassword'] = undefined;

/**
 * @member {Array.<String>} actions
 */
PluginConfigurationFile.prototype['actions'] = undefined;
var _default = exports.default = PluginConfigurationFile;