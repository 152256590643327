"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SystemVariable model module.
 * @module model/SystemVariable
 * @version 2.5.0
 */
class SystemVariable {
  /**
   * Constructs a new <code>SystemVariable</code>.
   * A system settings variable.  This is also used as a storage for Storage\\Variables with the prefix \&quot;__storage__\&quot; if APCu is not available.
   * @alias module:model/SystemVariable
   * @param name {String} Variable name.
   * @param value {String} Variable value.
   */
  constructor(name, value) {
    SystemVariable.initialize(this, name, value);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, name, value) {
    obj['name'] = name;
    obj['value'] = value;
  }

  /**
   * Constructs a <code>SystemVariable</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SystemVariable} obj Optional instance to populate.
   * @return {module:model/SystemVariable} The populated <code>SystemVariable</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SystemVariable();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('value')) {
        obj['value'] = _ApiClient.default.convertToType(data['value'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SystemVariable</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SystemVariable</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of SystemVariable.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['value'] && !(typeof data['value'] === 'string' || data['value'] instanceof String)) {
      throw new Error("Expected the field `value` to be a primitive type in the JSON string but got " + data['value']);
    }
    return true;
  }
}
SystemVariable.RequiredProperties = ["name", "value"];

/**
 * Variable name.
 * @member {String} name
 */
SystemVariable.prototype['name'] = undefined;

/**
 * Variable value.
 * @member {String} value
 */
SystemVariable.prototype['value'] = undefined;
var _default = exports.default = SystemVariable;