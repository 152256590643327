"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Alliance = _interopRequireDefault(require("../model/Alliance"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Alliance service.
* @module api/AllianceApi
* @version 2.5.0
*/
class AllianceApi {
  /**
  * Constructs a new AllianceApi. 
  * @alias module:api/AllianceApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the add operation.
   * @callback module:api/AllianceApi~addCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Alliance} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add an EVE alliance to the database.
   * Needs role: group-admin, watchlist-manager      *                  This makes an ESI request and adds the alliance only if it exists
   * @param {Number} id EVE alliance ID.
   * @param {module:api/AllianceApi~addCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Alliance}
   */
  add(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling add");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Alliance.default;
    return this.apiClient.callApi('/user/alliance/add/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addGroup operation.
   * @callback module:api/AllianceApi~addGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add a group to the alliance.
   * Needs role: group-admin
   * @param {Number} id ID of the alliance.
   * @param {Number} gid ID of the group.
   * @param {module:api/AllianceApi~addGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  addGroup(id, gid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addGroup");
    }
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling addGroup");
    }
    let pathParams = {
      'id': id,
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/alliance/{id}/add-group/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeGroup operation.
   * @callback module:api/AllianceApi~removeGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a group from the alliance.
   * Needs role: group-admin
   * @param {Number} id ID of the alliance.
   * @param {Number} gid ID of the group.
   * @param {module:api/AllianceApi~removeGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeGroup(id, gid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling removeGroup");
    }
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling removeGroup");
    }
    let pathParams = {
      'id': id,
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/alliance/{id}/remove-group/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userAllianceAlliances operation.
   * @callback module:api/AllianceApi~userAllianceAlliancesCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Alliance>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns alliances found by ID.
   * Needs role: group-admin, watchlist-manager, settings
   * @param {Array.<Number>} requestBody EVE IDs of alliances.
   * @param {module:api/AllianceApi~userAllianceAlliancesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Alliance>}
   */
  userAllianceAlliances(requestBody, callback) {
    let postBody = requestBody;
    // verify the required parameter 'requestBody' is set
    if (requestBody === undefined || requestBody === null) {
      throw new Error("Missing the required parameter 'requestBody' when calling userAllianceAlliances");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = [_Alliance.default];
    return this.apiClient.callApi('/user/alliance/alliances', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userAllianceFind operation.
   * @callback module:api/AllianceApi~userAllianceFindCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Alliance>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns a list of alliances that matches the query (partial matching name or ticker).
   * Needs role: group-admin, watchlist-manager, settings
   * @param {String} query Name or ticker of the alliance (min. 3 characters).
   * @param {module:api/AllianceApi~userAllianceFindCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Alliance>}
   */
  userAllianceFind(query, callback) {
    let postBody = null;
    // verify the required parameter 'query' is set
    if (query === undefined || query === null) {
      throw new Error("Missing the required parameter 'query' when calling userAllianceFind");
    }
    let pathParams = {
      'query': query
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Alliance.default];
    return this.apiClient.callApi('/user/alliance/find/{query}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the withGroups operation.
   * @callback module:api/AllianceApi~withGroupsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Alliance>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all alliances that have groups assigned.
   * Needs role: group-admin
   * @param {module:api/AllianceApi~withGroupsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Alliance>}
   */
  withGroups(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Alliance.default];
    return this.apiClient.callApi('/user/alliance/with-groups', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AllianceApi;