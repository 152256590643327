"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Group model module.
 * @module model/Group
 * @version 2.5.0
 */
class Group {
  /**
   * Constructs a new <code>Group</code>.
   * @alias module:model/Group
   * @param id {Number} Group ID.
   * @param name {String} A unique group name (can be changed).
   */
  constructor(id, name) {
    Group.initialize(this, id, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name) {
    obj['id'] = id;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>Group</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Group} obj Optional instance to populate.
   * @return {module:model/Group} The populated <code>Group</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Group();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('visibility')) {
        obj['visibility'] = _ApiClient.default.convertToType(data['visibility'], 'String');
      }
      if (data.hasOwnProperty('autoAccept')) {
        obj['autoAccept'] = _ApiClient.default.convertToType(data['autoAccept'], 'Boolean');
      }
      if (data.hasOwnProperty('isDefault')) {
        obj['isDefault'] = _ApiClient.default.convertToType(data['isDefault'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Group</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Group</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Group.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['visibility'] && !(typeof data['visibility'] === 'string' || data['visibility'] instanceof String)) {
      throw new Error("Expected the field `visibility` to be a primitive type in the JSON string but got " + data['visibility']);
    }
    return true;
  }
}
Group.RequiredProperties = ["id", "name"];

/**
 * Group ID.
 * @member {Number} id
 */
Group.prototype['id'] = undefined;

/**
 * A unique group name (can be changed).
 * @member {String} name
 */
Group.prototype['name'] = undefined;

/**
 * @member {String} description
 */
Group.prototype['description'] = undefined;

/**
 * @member {module:model/Group.VisibilityEnum} visibility
 */
Group.prototype['visibility'] = undefined;

/**
 * @member {Boolean} autoAccept
 */
Group.prototype['autoAccept'] = undefined;

/**
 * @member {Boolean} isDefault
 */
Group.prototype['isDefault'] = undefined;

/**
 * Allowed values for the <code>visibility</code> property.
 * @enum {String}
 * @readonly
 */
Group['VisibilityEnum'] = {
  /**
   * value: "private"
   * @const
   */
  "private": "private",
  /**
   * value: "public"
   * @const
   */
  "public": "public"
};
var _default = exports.default = Group;