"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Player = _interopRequireDefault(require("./Player"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RemovedCharacter model module.
 * @module model/RemovedCharacter
 * @version 2.5.0
 */
class RemovedCharacter {
  /**
   * Constructs a new <code>RemovedCharacter</code>.
   * @alias module:model/RemovedCharacter
   * @param characterId {Number} EVE character ID.
   * @param characterName {String} EVE character name.
   * @param removedDate {Date} Date of removal.
   * @param reason {module:model/RemovedCharacter.ReasonEnum} How it was removed (deleted or moved to another account).
   */
  constructor(characterId, characterName, removedDate, reason) {
    RemovedCharacter.initialize(this, characterId, characterName, removedDate, reason);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, characterId, characterName, removedDate, reason) {
    obj['characterId'] = characterId;
    obj['characterName'] = characterName;
    obj['removedDate'] = removedDate;
    obj['reason'] = reason;
  }

  /**
   * Constructs a <code>RemovedCharacter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RemovedCharacter} obj Optional instance to populate.
   * @return {module:model/RemovedCharacter} The populated <code>RemovedCharacter</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RemovedCharacter();
      if (data.hasOwnProperty('newPlayerId')) {
        obj['newPlayerId'] = _ApiClient.default.convertToType(data['newPlayerId'], 'Number');
      }
      if (data.hasOwnProperty('newPlayerName')) {
        obj['newPlayerName'] = _ApiClient.default.convertToType(data['newPlayerName'], 'String');
      }
      if (data.hasOwnProperty('player')) {
        obj['player'] = _Player.default.constructFromObject(data['player']);
      }
      if (data.hasOwnProperty('characterId')) {
        obj['characterId'] = _ApiClient.default.convertToType(data['characterId'], 'Number');
      }
      if (data.hasOwnProperty('characterName')) {
        obj['characterName'] = _ApiClient.default.convertToType(data['characterName'], 'String');
      }
      if (data.hasOwnProperty('removedDate')) {
        obj['removedDate'] = _ApiClient.default.convertToType(data['removedDate'], 'Date');
      }
      if (data.hasOwnProperty('reason')) {
        obj['reason'] = _ApiClient.default.convertToType(data['reason'], 'String');
      }
      if (data.hasOwnProperty('deletedBy')) {
        obj['deletedBy'] = _Player.default.constructFromObject(data['deletedBy']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RemovedCharacter</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RemovedCharacter</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of RemovedCharacter.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['newPlayerName'] && !(typeof data['newPlayerName'] === 'string' || data['newPlayerName'] instanceof String)) {
      throw new Error("Expected the field `newPlayerName` to be a primitive type in the JSON string but got " + data['newPlayerName']);
    }
    // validate the optional field `player`
    if (data['player']) {
      // data not null
      _Player.default.validateJSON(data['player']);
    }
    // ensure the json data is a string
    if (data['characterName'] && !(typeof data['characterName'] === 'string' || data['characterName'] instanceof String)) {
      throw new Error("Expected the field `characterName` to be a primitive type in the JSON string but got " + data['characterName']);
    }
    // ensure the json data is a string
    if (data['reason'] && !(typeof data['reason'] === 'string' || data['reason'] instanceof String)) {
      throw new Error("Expected the field `reason` to be a primitive type in the JSON string but got " + data['reason']);
    }
    // validate the optional field `deletedBy`
    if (data['deletedBy']) {
      // data not null
      _Player.default.validateJSON(data['deletedBy']);
    }
    return true;
  }
}
RemovedCharacter.RequiredProperties = ["characterId", "characterName", "removedDate", "reason"];

/**
 * @member {Number} newPlayerId
 */
RemovedCharacter.prototype['newPlayerId'] = undefined;

/**
 * @member {String} newPlayerName
 */
RemovedCharacter.prototype['newPlayerName'] = undefined;

/**
 * @member {module:model/Player} player
 */
RemovedCharacter.prototype['player'] = undefined;

/**
 * EVE character ID.
 * @member {Number} characterId
 */
RemovedCharacter.prototype['characterId'] = undefined;

/**
 * EVE character name.
 * @member {String} characterName
 */
RemovedCharacter.prototype['characterName'] = undefined;

/**
 * Date of removal.
 * @member {Date} removedDate
 */
RemovedCharacter.prototype['removedDate'] = undefined;

/**
 * How it was removed (deleted or moved to another account).
 * @member {module:model/RemovedCharacter.ReasonEnum} reason
 */
RemovedCharacter.prototype['reason'] = undefined;

/**
 * @member {module:model/Player} deletedBy
 */
RemovedCharacter.prototype['deletedBy'] = undefined;

/**
 * Allowed values for the <code>reason</code> property.
 * @enum {String}
 * @readonly
 */
RemovedCharacter['ReasonEnum'] = {
  /**
   * value: "moved"
   * @const
   */
  "moved": "moved",
  /**
   * value: "moved-owner-changed"
   * @const
   */
  "moved-owner-changed": "moved-owner-changed",
  /**
   * value: "deleted-biomassed"
   * @const
   */
  "deleted-biomassed": "deleted-biomassed",
  /**
   * value: "deleted-owner-changed"
   * @const
   */
  "deleted-owner-changed": "deleted-owner-changed",
  /**
   * value: "deleted-lost-access"
   * @const
   */
  "deleted-lost-access": "deleted-lost-access",
  /**
   * value: "deleted-manually"
   * @const
   */
  "deleted-manually": "deleted-manually"
};
var _default = exports.default = RemovedCharacter;